import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import FooterSection from "../Components/Footer";

const Fabrication = () => {
  const [formData, setFormData] = useState({
    fabricationType: "",
    height: "",
    width: "",
    numberOfLetters: "",
    color: "",
    fixing: "",
    material: "",
  });
  const [validated, setValidated] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // Submit the form data
      // For demonstration purposes, just log the form data
      console.log(formData);
      setShowSuccessMessage(true);
    }
    setValidated(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div>
      <Container className="card container d-flex px-5 ">
        <h1 className="mt-5 mb-4">Fabrication</h1>
        <Row>
          <Col md={12}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId="formFabricationType">
                <Form.Label>Type of Fabrication</Form.Label>
                <Form.Control
                  type="text"
                  name="fabricationType"
                  value={formData.fabricationType}
                  onChange={handleChange}
                  placeholder="Enter type of fabrication"
                  required
                  style={{backgroundColor :"#1be96a37", marginBottom:"10px", padding:"10px"}}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a type of fabrication.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formHeight">
                <Form.Label>Height (in inches)</Form.Label>
                <Form.Control
                  type="number"
                  name="height"
                  value={formData.height}
                  onChange={handleChange}
                  placeholder="Enter height"
                  required
                  style={{backgroundColor :"#1be96a37", marginBottom:"10px", padding:"10px"}}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide the height in inches.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formWidth">
                <Form.Label>Width (in inches)</Form.Label>
                <Form.Control
                  type="number"
                  name="width"
                  value={formData.width}
                  onChange={handleChange}
                  placeholder="Enter width"
                  required
                  style={{backgroundColor :"#1be96a37", marginBottom:"10px", padding:"10px"}}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide the width in inches.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formNumberOfLetters">
                <Form.Label>Number of Letters</Form.Label>
                <Form.Control
                  type="number"
                  name="numberOfLetters"
                  value={formData.numberOfLetters}
                  onChange={handleChange}
                  placeholder="Enter number of letters"
                  required
                  style={{backgroundColor :"#1be96a37", marginBottom:"10px", padding:"10px"}}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide the number of letters.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formColor">
                <Form.Label>Color</Form.Label>
                <Form.Control
                  type="text"
                  name="color"
                  value={formData.color}
                  onChange={handleChange}
                  placeholder="Enter color"
                  required
                  style={{backgroundColor :"#1be96a37", marginBottom:"10px", padding:"10px"}}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a color.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formFixing">
                <Form.Label>Enter the Fixing</Form.Label>
                <Form.Control
                  type="text"
                  name="fixing"
                  value={formData.fixing}
                  onChange={handleChange}
                  placeholder="Enter fixing"
                  required
                  style={{backgroundColor :"#1be96a37", marginBottom:"10px", padding:"10px"}}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide the fixing details.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formMaterial">
                <Form.Label>Material</Form.Label>
                <Form.Control
                  type="text"
                  name="material"
                  value={formData.material}
                  onChange={handleChange}
                  placeholder="Enter material"
                  required
                  style={{backgroundColor :"#1be96a37", marginBottom:"10px", padding:"10px"}}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide the material details.
                </Form.Control.Feedback>
              </Form.Group>

              <Button
                style={{
                  background: "linear-gradient(to bottom, #578b23, #1d5532",
                  color: "#fff",
                  width: "200px",
                  textAlign: "center",
                  borderRadius: "10px",
                  height: "45px",
                  marginBottom: "5px",
                }}
                type="submit"
              >
                Submit
              </Button>
              {showSuccessMessage && (
                <Alert variant="success" className="mt-3">
                  Your form has been submitted successfully!
                </Alert>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
      <FooterSection />
    </div>
  );
};

export default Fabrication;
