import React from "react";
import Products from "../Components/Products";
import FooterSection from "../Components/Footer";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Accessories = () => {
  // Use the useLocation hook to get the location object
  const location = useLocation();

  // Access the state object from the location object
  const { data, id } = location.state || {};

  return (
    <div>
      <Products productData={data} />

      <FooterSection />
    </div>
  );
};

export default Accessories;
