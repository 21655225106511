import React from "react";
import { useCartContext } from "../context/cart_context";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import "../CustomStyling/ShopCart.css";
import {
  ArrowLeftOutlined,
  DeleteFilled,
  LeftOutlined,
} from "@ant-design/icons";

const ShopCart = () => {
  const { cart, total_price } = useCartContext();
  const history = useHistory();
  const handleCheck = () => {
    history.push("/Check-out");
  };
  return (
    <div className="cart_area">
      <h4 className="text-center">Shopping Cart</h4>
      <div className="shop_cart d-flex col-md-12  mt-4">
        <div className="cart_left col-md-9">
          <div className="d-flex align-items-center justify-content-between col-md-12 mb-2">
            <div className="continue__ d-flex align-items-center justify-content-between">
              <ArrowLeftOutlined />
              <span>Continue Shop</span>
            </div>
            <div className="total_item">
              <label>Total Items: {cart.length}</label>
            </div>
          </div>
          <div className="container card">
            {/* <div className="titles__ d-flex ">
              <p>Product</p>
              <p>Price</p>
              <p>Quantity</p>
              <p>Remove</p>
            </div> */}
            {cart.map((item) => (
              <div key={item.id} className="card rounded-3 mb-2 mt-2 col-md-12 item_card">
                <div className="">{RenderCartItem(item)}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="cart_right container col-md-3">
          <h5>Order Summary</h5>
          <hr />
          <h6 className="d-flex align-items-center justify-content-between">
            <span>Subtotal:</span>
            <span>${total_price}</span>
          </h6>
          <h6 className="d-flex align-items-center justify-content-between">
            <span>Discount:</span>
            <span>$0</span>
          </h6>
          <h6 className="d-flex align-items-center justify-content-between">
            <span>Shipping fee:</span>
            <span>$0</span>
          </h6>
          <hr />
          <h6 className="d-flex align-items-center justify-content-between">
            <span>Total Amount:</span>
            <span>${total_price}</span>
          </h6>
          <button onClick={handleCheck}>Check Out</button>
        </div>
      </div>
    </div>
  );
};

const RenderCartItem = (item) => {
  const { removeItem } = useCartContext();
  return (
    <>
      <div className="col-md-12 titles__">
        <div className="product__ d-flex col-md-6">
          <div className="item_img" style={{ height: "100px", width: "200px" }}>
            <img
              src={item.image}
              alt=""
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div className="product_title ">
            <label>
             {item.title}
            </label>
            {/* <p
              style={{
                backgroundColor: item,
                height: "22px",
                width: "22px",
                borderRadius: "50%",
              }}
            ></p> */}
          </div>
        </div>
        <div className="item_price col-md-1">
          <span>Price: ${item.price}</span>
        </div>
        <div className="item_qty col-md-1">
          <div className="quantity_area ">
            {/* <button className="minus_ shadow">-</button> */}
            <span className="">Qty: {item.quantity}</span>
            {/* <button className="plus_ shadow">+</button> */}
          </div>
        </div>
        <div className="item_remove col-md-1">
          <DeleteFilled onClick={() => removeItem(item.c_id)} color="#1d5532" />
        </div>
      </div>
    </>
  );
};

export default ShopCart;
