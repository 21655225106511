import React from "react";
import "../CustomStyling/AboutUs.css";

const AboutUs = () => {
  return (
    <div className="about-main">
      <h1 className="text-center">AboutUS</h1>
      
    </div>
  );
};

export default AboutUs;
