import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import Fabrication from "./Pages/Fabrication";
import ContactUs from "./Pages/ContactUs";
import AboutUs from "./Pages/AboutUs";
import Qoute from "./Pages/Quote";
import RigidSheet from "./Pages/RigidSheet";
import LightBoxes from "./Pages/LightBoxes";
import Accessories from "./Pages/Accessories";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import ProductDetail from "./Pages/ProductDetail";
import ShopCart from "./Pages/ShopCart";
import SubProducts from "./Pages/SubProducts";

const App = () => {
  const [response, setResponse] = useState([]);
  useEffect(() => {
    const fetchData = () => {
      const requestBody = {
        category: "parent", // Adjust based on your API requirements
        // ... other parameters
      };

      fetch("https://mcrsupplies.co.uk/admin/php-api/product_category.php", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((responseData) => {
          // console.log("API Response:", responseData);
          setResponse(responseData.data);
        })
        .catch((error) => {
          console.error("API Error:", error.message);
        });
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []);
  return (
    <>
      {/* {!isCartDataRoute &&!isPaymentRoute && <Navbar />} */}
      <Navbar response={response} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Fabrication" component={Fabrication} />
        <Route exact path="/ContactUs" component={ContactUs} />
        <Route exact path="/AboutUS" component={AboutUs} />
        <Route exact path="/Get-A-Qoute" component={Qoute} />
        <Route exact path="/Rigid Sheet" component={RigidSheet} />
        <Route exact path="/Light Box" component={LightBoxes} />
        <Route exact path="/Accessories" component={Accessories} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signUp" component={Register} />
        <Route exact path="/product-detail" component={ProductDetail} />
        <Route exact path="/cart" component={ShopCart} />
        <Route exact path="/sub-products" component={SubProducts} />
        <Route exact path="/Products" />
        <Redirect to="/" />
      </Switch>
    </>
  );
};

export default App;
