import React, { useEffect, useState } from "react";
import MainSlider from "../Components/MainSlider";
import Main from "../Components/Main";
import Categories from "../Components/Categories";
import FooterSection from "../Components/Footer";
import AnimationComponent from "../Components/AnimationComponent";

const Home = () => {
  const [productData, setProductData] = useState([]);
  useEffect(() => {
    const fetchData = () => {
      const requestBody = {
        category: "parent", // Adjust based on your API requirements
        // ... other parameters
      };

      fetch("https://mcrsupplies.co.uk/admin/php-api/product_category.php", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((responseData) => {
          // console.log("API Response:", responseData);
          setProductData(responseData.data);
        })
        .catch((error) => {
          console.error("API Error:", error.message);
        });
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []);
  return (
    <div>
      <Main />
      <Categories  productData={productData}/>
      <FooterSection />
    </div>
  );
};

export default Home;
