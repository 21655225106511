import { createContext, useContext, useEffect, useReducer } from "react";
import cartReducer from "../reducer/cartReducer";

const CartContext = createContext();

const getLocalCartData = () => {
  let newCartData = localStorage.getItem("cartData");
  try {
    if (!newCartData || JSON.parse(newCartData).length === 0) {
      return [];
    } else {
      return JSON.parse(newCartData);
    }
  } catch (error) {
    console.error("Error parsing cart data:", error);
    return [];
  }
};
const initialState = {
  // cart: [],
  cart: getLocalCartData(),
  total_price: "",
  
};

const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const addToCart = (productInfo) => {
    dispatch({ type: "ADD_TO_CART", payload: productInfo });
  };

  const removeItem = (id) => {
    dispatch({ type: "REMOVE_ITEM", payload: id });
  };

  // to clear cart items
  const clearCart = () => {
    dispatch({ type: "CLEAR_CART" });
  };

  // ! Add data in Local Storage
  useEffect(() => {
    localStorage.setItem("cartData", JSON.stringify(state.cart));
    dispatch({ type: "CART_TOTAL_PRICE" });
    return () => {};
  }, [state.cart]);

  return (
    <CartContext.Provider
      value={{ ...state, addToCart, removeItem, clearCart }}
    >
      {children}
    </CartContext.Provider>
  );
};

const useCartContext = () => {
  return useContext(CartContext);
};

export { CartProvider, useCartContext };
