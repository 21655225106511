import React, { useEffect, useState } from "react";
import "../CustomStyling/Categories.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Categories = ({ productData }) => {
  // console.log("Categories", productData);
  useEffect(() => {
    AOS.init({ duration: 1500 });
  });
  const history = useHistory();


  const handelProduct = (id, path) => {
    const requestBody = {
      category: "sub",
      p_category_id: id,
    };
    console.log("CategoryId", id);
    fetch("https://mcrsupplies.co.uk/admin/php-api/product_category.php", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        // Navigating to the new page and passing the data via state
        history.push({
          pathname: `/${path}`,
          state: { data: responseData.data, pid: id },
        });
      })
      .catch((error) => {
        console.error("API Error:", error.message);
      });
  };
  return (
    <div>
      <div className="category_section">
        <h4 className="text-center">Categories</h4>
        <div className="description-container">
          {productData.map((product, index) => (
            <div
              key={index}
              className={`description-content  ${
                index % 2 === 1 ? "reverse" : ""
              }`}
              data-aos="zoom-in-left"
              data-aos-duration="1500"
              
            >
              <div className="description-image" data-aos="flip-left"
              data-aos-duration="1000">
                <img src={product.image} alt={product.image} />
              </div>
              <div className="description-details">
                <h3>{product.category_name}</h3>
                <p>{product.category_description}</p>
                <a
                  onClick={() =>
                    handelProduct(product.id, product.category_name)
                  }
                  className=" gradient-button"
                >
                  Products
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
