import React, { useState } from "react";
import "../CustomStyling/Register.css";
import { Link } from "react-router-dom";
import { CameraOutlined } from "@ant-design/icons";
import { message } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Register = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    townCity: "",
    postalCode: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    companyPhone: "",
    companyEmail: "",
    businessIdentityDocument: null,
    agreePrivacyPolicy: false,
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    townCity: "",
    postalCode: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    companyPhone: "",
    companyEmail: "",
    businessIdentityDocument: null,
    agreePrivacyPolicy: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    // Validate Full Name
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First Name is required";
      valid = false;
    } else {
      newErrors.firstName = "";
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last Name is required";
      valid = false;
    } else {
      newErrors.lastName = "";
    }

    // Validate Phone Number
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone Number is required";
      valid = false;
    } else if (!/^\d{11}$/i.test(formData.phone.trim())) {
      newErrors.phone = "Invalid ! Add 11 digits";
      valid = false;
    } else {
      newErrors.phone = "";
    }

    // Validate Email Address
    if (!formData.email.trim()) {
      newErrors.email = "Email Address is required";
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email.trim())) {
      newErrors.email = "Invalid email format";
      valid = false;
    } else {
      newErrors.email = "";
    }

    // Validate Full Address
    if (!formData.addressLine1.trim()) {
      newErrors.addressLine1 = " Address is required";
      valid = false;
    } else {
      newErrors.addressLine1 = "";
    }

    // Validate City
    if (!formData.townCity.trim()) {
      newErrors.townCity = "City or Town is required";
      valid = false;
    } else {
      newErrors.townCity = "";
    }

    // Validate Zip Code
    if (!formData.postalCode.trim()) {
      newErrors.postalCode = "Postal Code is required";
      valid = false;
    } else {
      newErrors.postalCode = "";
    }
    // Validate Password
    if (!formData.password.trim()) {
      newErrors.password = "password  is required";
      valid = false;
    } else {
      newErrors.password = "";
    }

    if (!formData.confirmPassword.trim()) {
      newErrors.confirmPassword = "Password is required";
      valid = false;
    } else {
      if (formData.password === formData.confirmPassword) {
        newErrors.confirmPassword = "";
      } else {
        newErrors.confirmPassword = "Password and confirm password are same";
        valid = false;
      }
    }
    if (!formData.companyName.trim()) {
      newErrors.companyName = "Company name is required";
      valid = false;
    } else {
      newErrors.companyName = "";
    }

    if (!formData.companyEmail.trim()) {
      newErrors.companyEmail = "Company Email Address is required";
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.companyEmail.trim())) {
      newErrors.companyEmail = "Invalid email format";
      valid = false;
    } else {
      newErrors.companyEmail = "";
    }

    if (!formData.companyPhone.trim()) {
      newErrors.companyPhone = " Company Phone Number is required";
      valid = false;
    } else if (!/^\d{11}$/i.test(formData.phone.trim())) {
      newErrors.companyPhone = "Invalid ! Add 11 digits";
      valid = false;
    } else {
      newErrors.phone = "";
    }

    if (formData.businessIdentityDocument === null) {
      newErrors.businessIdentityDocument = " A business Identity is required";
      valid = false;
    } else {
      newErrors.businessIdentityDocument = "";
    }
    if (formData.agreePrivacyPolicy === false) {
      newErrors.agreePrivacyPolicy = "This is required";
      valid = false;
    } else {
      newErrors.agreePrivacyPolicy = "";
    }

    setErrors(newErrors);
    return valid;
  };
  const handleSubmit = async (e) => {
    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      phone: formData.phone,
      email: formData.email,
      address: formData.addressLine1 + " " + formData.addressLine2,

      townCity: formData.townCity,
      postalCode: formData.postalCode,
      password: formData.password,
      companyName: formData.companyName,
      companyPhone: formData.companyPhone,
      companyEmail: formData.companyEmail,
      businessIdentityDocument: formData.businessIdentityDocument,
    };

    e.preventDefault();

    if (validateForm()) {
      try {
        const response = await fetch(
          "https://mcrsupplies.co.uk/admin/php-api/register.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (response.ok) {
          const data = await response.json();
          // Check if registration was successful
          if (data.success) {
            // Navigate to the next page (e.g., home page)
            history.push("/");
            console.log("Registration successful:", data);
            message.success("Register success");

            // Reset form data
            setFormData({
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              addressLine1: "",
              addressLine2: "",
              townCity: "",
              postalCode: "",
              password: "",
              confirmPassword: "",
              companyName: "",
              companyPhone: "",
              companyEmail: "",
              businessIdentityDocument: null,
              agreePrivacyPolicy: false,
            });
          } else {
            // Handle registration errors, e.g., display error message
            console.error("Registration failed:", data.message);
            message.error("Registration failed: " + data.message);
          }
        } else {
          // Handle registration errors, e.g., display error message
          console.error("Registration failed:", response.statusText);
          message.error("Registration failed: " + response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
        message.error("An error occurred while registering.");
      }
    } else {
      message.error("Form Is Not Validated");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, businessIdentityDocument: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="signup__container">
      <div className="signup__form">
        <h2>Sign Up</h2>
        <div className="personal__info">
          <h5>Personal Info</h5>
          <div className="input__group">
            <div className="input__container">
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="First Name"
              />
              <small className="text-danger">{errors.firstName}</small>
            </div>
            <div className="input__container">
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Last Name"
              />
              <small className="text-danger">{errors.lastName}</small>
            </div>
          </div>
          <div className="input__group">
            <div className="input__container">
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone"
              />
              <small className="text-danger">{errors.phone}</small>
            </div>
            <div className="input__container">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
              />
              <small className="text-danger">{errors.email}</small>
            </div>
          </div>
          <div className="input__group">
            <div className="input__container">
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
              />
              <small className="text-danger">{errors.password}</small>
            </div>
            <div className="input__container">
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm Password"
              />
              <small className="text-danger">{errors.confirmPassword}</small>
            </div>
          </div>
        </div>
        <div className="business__info">
          <h5>Business Info</h5>
          <div className="input__group">
            <div className="input__container">
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                placeholder="Company Name"
              />
              <small className="text-danger">{errors.companyName}</small>
            </div>
            <div className="input__container">
              <input
                type="tel"
                name="companyPhone"
                value={formData.companyPhone}
                onChange={handleChange}
                placeholder="Company Phone"
              />
              <small className="text-danger">{errors.companyPhone}</small>
            </div>
          </div>
          <div className="input__group">
            <div className="input__container">
              <input
                type="email"
                name="companyEmail"
                value={formData.companyEmail}
                onChange={handleChange}
                placeholder="Company Email"
              />
              <small className="text-danger">{errors.companyEmail}</small>
            </div>
          </div>
          <div className="upload__box">
            <h5 className="upload__label">Business Identity Document</h5>
            <label className="box__img" htmlFor="businessIdentityDocument">
              <span>
                {formData.businessIdentityDocument
                  ? "Image Uploaded"
                  : "Upload Image"}
              </span>
              <CameraOutlined className="camera__icon" />
            </label>
            <input
              type="file"
              id="businessIdentityDocument"
              name="businessIdentityDocument"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <small className="text-danger">
              {errors.businessIdentityDocument}
            </small>
          </div>
        </div>
        <div className="address__info">
          <h5>Address Info</h5>
          <div className="input__group">
            <div className="input__container">
              <input
                type="text"
                name="addressLine1"
                value={formData.addressLine1}
                onChange={handleChange}
                placeholder="Address Line 1"
              />
              <small className="text-danger">{errors.addressLine1}</small>
            </div>
            <div className="input__container">
              <input
                type="text"
                name="addressLine2"
                value={formData.addressLine2}
                onChange={handleChange}
                placeholder="Address Line 2"
              />
            </div>
          </div>
          <div className="input__group">
            <div className="input__container">
              <input
                type="text"
                name="townCity"
                value={formData.townCity}
                onChange={handleChange}
                placeholder="Town / City"
              />
              <small className="text-danger">{errors.townCity}</small>
            </div>
            <div className="input__container">
              <input
                type="text"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
                placeholder="Postal Code"
              />
              <small className="text-danger">{errors.postalCode}</small>
            </div>
          </div>
          <small className="text-danger">{errors.agreePrivacyPolicy}</small>
          <div className="checkbox__group">
            <input
              type="checkbox"
              id="agreePrivacyPolicy"
              name="agreePrivacyPolicy"
              checked={formData.agreePrivacyPolicy}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="agreePrivacyPolicy">
              I have agreed with Privacy Policy
            </label>
          </div>
        </div>
        <button className="signup__button" onClick={handleSubmit}>
          Sign Up
        </button>
        <p className="login__">
          Already have an account? <Link to="/login">Login</Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
