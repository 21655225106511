import React from "react";
import FooterSection from "../Components/Footer";
import Products from "../Components/Products";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import SubProductCategory from "../Components/SubProductCategory";

const SubProducts = () => {
  // Use the useLocation hook to get the location object
  const location = useLocation();

  // Access the state object from the location object
  const { data, pid } = location.state;
  // Check if data is empty
  if (!data || data.length === 0) {
    return (
      <div style={{ marginTop: "50px", height: "100vh" }}>
        <h2 style={{ height: "70vh", textAlign: "center" }}>Coming Soon</h2>
        <FooterSection />
      </div>
    );
  }

  return (
    <div>
      <SubProductCategory productData={data} />
      <FooterSection />
    </div>
  );
};

export default SubProducts;
