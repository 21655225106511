import React, { useState } from "react";
import FooterSection from "../Components/Footer";
import ProductDataView from "../Components/ProductDataView";
import "../CustomStyling/ProductDetail.css";
import Reviews from "../Components/Reviews";
import FAQ from "../Components/FAQ";
import ProductDescription from "../Components/ProductDescrption";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const ProductDetail = () => {
  const [activeTab, setActiveTab] = useState("description");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  // Use the useLocation hook to get the location object
  const location = useLocation();

  // Access the state object from the location object
  const { data, pid, title } = location.state;
  // console.log(data);
  const descriptionData = {
    title: "Product Description",
    points: [
      "High-quality materials used in construction",
      "Modern design with sleek finishes",
      "Multiple color options available",
      "Easy to assemble and maintain",
      "Suitable for both indoor and outdoor use",
    ],
    details: [
      {
        heading: "Dimensions",
        content:
          "Length: 120cm, Width: 80cm, Height: 60cm Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda rerum est quaerat nisi? Vitae ratione nostrum, ducimus harum ad perferendis doloribus dolorem nesciunt cum atque laborum voluptatem, eius provident asperiores?  ",
      },
      {
        heading: "Material",
        content:
          "Durable aluminum frame with weather-resistant coating Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda rerum est quaerat nisi? Vitae ratione nostrum, ducimus harum ad perferendis doloribus dolorem nesciunt cum atque laborum voluptatem, eius provident asperiores?  ",
      },
      {
        heading: "Assembly",
        content:
          "Comes with easy-to-follow assembly instructions Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda rerum est quaerat nisi? Vitae ratione nostrum, ducimus harum ad perferendis doloribus dolorem nesciunt cum atque laborum voluptatem, eius provident asperiores?  ",
      },
      {
        heading: "Care Instructions",
        content:
          "Wipe clean with a damp cloth Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda rerum est quaerat nisi? Vitae ratione nostrum, ducimus harum ad perferendis doloribus dolorem nesciunt cum atque laborum voluptatem, eius provident asperiores?  ",
      },
      // Add more details as needed
    ],
  };

  const reviewData = [
    {
      id: 1,
      name: "John Doe",
      designation: "Customer",
      rating: 4.5,
      picture: "john-doe.jpg",
      review:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla libero turpis, convallis sed mauris nec, hendrerit suscipit est.",
    },
    {
      id: 2,
      name: "Jane Smith",
      designation: "Customer",
      rating: 5,
      picture: "jane-smith.jpg",
      review:
        "Sed ultricies consequat ante, vel ultrices tortor dictum ut. Duis nec consectetur justo. Fusce eget tellus vel justo sodales congue. Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa labore quibusdam repellendus eum molestiae saepe recusandae sit praesentium animi, accusantium, facere reprehenderit magnam quos! Molestiae officia expedita omnis fugit odio.",
    },
    {
      id: 3,
      name: "John Doe",
      designation: "Customer",
      rating: 4.5,
      picture: "john-doe.jpg",
      review:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla libero turpis, convallis sed mauris nec, hendrerit suscipit est.",
    },
    {
      id: 4,
      name: "Jane Smith",
      designation: "Customer",
      rating: 5,
      picture: "jane-smith.jpg",
      review:
        "Sed ultricies consequat ante, vel ultrices tortor dictum ut. Duis nec consectetur justo. Fusce eget tellus vel justo sodales congue. Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa labore quibusdam repellendus eum molestiae saepe recusandae sit praesentium animi, accusantium, facere reprehenderit magnam quos! Molestiae officia expedita omnis fugit odio.",
    },
    {
      id: 5,
      name: "John Doe",
      designation: "Customer",
      rating: 4.5,
      picture: "john-doe.jpg",
      review:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla libero turpis, convallis sed mauris nec, hendrerit suscipit est.",
    },
    {
      id: 6,
      name: "Jane Smith",
      designation: "Customer",
      rating: 5,
      picture: "jane-smith.jpg",
      review:
        "Sed ultricies consequat ante, vel ultrices tortor dictum ut. Duis nec consectetur justo. Fusce eget tellus vel justo sodales congue. Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa labore quibusdam repellendus eum molestiae saepe recusandae sit praesentium animi, accusantium, facere reprehenderit magnam quos! Molestiae officia expedita omnis fugit odio.",
    },
    {
      id: 7,
      name: "John Doe",
      designation: "Customer",
      rating: 4.5,
      picture: "john-doe.jpg",
      review:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla libero turpis, convallis sed mauris nec, hendrerit suscipit est.",
    },
    {
      id: 8,
      name: "Jane Smith",
      designation: "Customer",
      rating: 5,
      picture: "jane-smith.jpg",
      review:
        "Sed ultricies consequat ante, vel ultrices tortor dictum ut. Duis nec consectetur justo. Fusce eget tellus vel justo sodales congue. Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa labore quibusdam repellendus eum molestiae saepe recusandae sit praesentium animi, accusantium, facere reprehenderit magnam quos! Molestiae officia expedita omnis fugit odio.",
    },
    {
      id: 9,
      name: "John Doe",
      designation: "Customer",
      rating: 4.5,
      picture: "john-doe.jpg",
      review:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla libero turpis, convallis sed mauris nec, hendrerit suscipit est.",
    },
    {
      id: 10,
      name: "Jane Smith",
      designation: "Customer",
      rating: 5,
      picture: "jane-smith.jpg",
      review:
        "Sed ultricies consequat ante, vel ultrices tortor dictum ut. Duis nec consectetur justo. Fusce eget tellus vel justo sodales congue. Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa labore quibusdam repellendus eum molestiae saepe recusandae sit praesentium animi, accusantium, facere reprehenderit magnam quos! Molestiae officia expedita omnis fugit odio.",
    },
    // Add more reviews as needed
  ];

  const faqData = [
    {
      id: 1,
      question: "What is the return policy?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla libero turpis, convallis sed mauris nec, hendrerit suscipit est.",
    },
    {
      id: 2,
      question: "How can I track my order?",
      answer:
        "Sed ultricies consequat ante, vel ultrices tortor dictum ut. Duis nec consectetur justo. Fusce eget tellus vel justo sodales congue.",
    },
    {
      id: 3,
      question: "How long does shipping take?",
      answer:
        "Shipping times vary depending on your location and the shipping method chosen at checkout. Please refer to our shipping policy for more information.",
    },
    {
      id: 4,
      question: "Do you offer international shipping?",
      answer:
        "Yes, we offer international shipping to many countries worldwide. However, there may be restrictions and additional fees depending on your location.",
    },
    {
      id: 5,
      question: "What payment methods do you accept?",
      answer:
        "We accept a variety of payment methods, including credit/debit cards, PayPal, and bank transfers. You can choose your preferred payment option during checkout.",
    },
    {
      id: 6,
      question: "Can I return or exchange an item?",
      answer:
        "Yes, we have a hassle-free return and exchange policy. If you're not satisfied with your purchase, you can return it within 30 days for a full refund or exchange.",
    },
    {
      id: 7,
      question: "Do you offer discounts for bulk orders?",
      answer:
        "Yes, we offer discounts for bulk orders. Please contact our customer support team for more information on bulk pricing and discounts.",
    },
    {
      id: 8,
      question: "Is my personal information secure?",
      answer:
        "Yes, we take the security of your personal information very seriously. We use industry-standard encryption and security measures to protect your data.",
    },
    {
      id: 9,
      question: "How can I contact customer support?",
      answer:
        "You can contact our customer support team via email, phone, or live chat. Our support representatives are available 24/7 to assist you with any questions or concerns.",
    },
    {
      id: 10,
      question: "Do you offer gift wrapping services?",
      answer:
        "Yes, we offer gift wrapping services for select items. You can choose the gift wrapping option during checkout and include a personalized message for the recipient.",
    },
    // Add more FAQ items as needed
  ];

  return (
    <div className="col-md-12" style={{ width: "100%" }}>
      <ProductDataView data={data} pid={pid} title={title} />
      {/* Tabs */}
      <div className="tab_area">
        <div className="tabs_">
          <button
            className={activeTab === "description" ? "active_" : ""}
            onClick={() => handleTabClick("description")}
          >
            Description
          </button>
          <button
            className={activeTab === "reviews" ? "active_" : ""}
            onClick={() => handleTabClick("reviews")}
          >
            Reviews
          </button>
          <button
            className={activeTab === "faq" ? "active_" : ""}
            onClick={() => handleTabClick("faq")}
          >
            FAQ
          </button>
        </div>
        <hr />
      </div>
      {/* Content based on active tab */}
      {activeTab === "description" && (
        <ProductDescription descriptionData={descriptionData} />
      )}
      {activeTab === "reviews" && <Reviews reviewData={reviewData} />}
      {activeTab === "faq" && <FAQ faqData={faqData} />}
      <FooterSection />
    </div>
  );
};

export default ProductDetail;
