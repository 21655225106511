const cartReducer = (state, action) => {
  if (action.type === "ADD_TO_CART") {
    let productInfo = action.payload;

    return {
      ...state,
      cart: [...state.cart, productInfo],
    };
  }

  if (action.type === "REMOVE_ITEM") {
    let updatedProductInfo = state.cart.filter(
      (curItem) => curItem.c_id !== action.payload
    );
    return {
      ...state,
      cart: updatedProductInfo,
    };
  }

  if (action.type === "CLEAR_CART") {
    return {
      ...state,
      cart: [],
    };
  }

  if (action.type === "CART_TOTAL_PRICE") {
    let total_price = state.cart.reduce((iniVal, curElm) => {
      let { price } = curElm;
      // iniVal = 0;

      iniVal = iniVal + price;
      console.log("Price...", iniVal);

      return iniVal;
    }, 0);

    return {
      ...state,
      total_price,
    };
  }

  return state;
};

export default cartReducer;
