import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import FooterSection from "../Components/Footer";

const ContactUs = () => {
  return (
    <>
      <div className="container card mt-5 mb-3">
        <Container>
          <h1 className="mt-5 mb-4">Contact Us</h1>
          <Row>
            <Col md={10}>
              <Form>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    style={{ backgroundColor: "#1be96a37" ,padding: "10px",}}
                    className="shadow mb-4"
                  />
                </Form.Group>

                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    style={{ backgroundColor: "#1be96a37" ,padding: "10px",}}
                    className="shadow mb-4"
                  />
                </Form.Group>

                <Form.Group controlId="formPhone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter your phone number"
                    style={{ backgroundColor: "#1be96a37" ,padding: "10px",}}
                    className="shadow mb-4"
                  />
                </Form.Group>

                <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your address"
                    style={{ backgroundColor: "#1be96a37" ,padding: "10px",}}
                    className="shadow mb-4"
                  />
                </Form.Group>

                <Form.Group controlId="formQuery">
                  <Form.Label>Query</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter your query"
                    style={{ backgroundColor: "#1be96a37" ,padding: "10px",}}
                    className="shadow mb-4"
                  />
                </Form.Group>

                <button
                  style={{
                    //  background: linear-gradient(to bottom, #578b23, #1d5532);
                    //  color: #fff;

                    background: "linear-gradient(to bottom, #578b23, #1d5532",
                    color: "#fff",
                    width: "200px",
                    textAlign: "center",
                    borderRadius: "10px",
                    height: "45px",
                    marginBottom: "5px",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <FooterSection />
    </>
  );
};

export default ContactUs;
