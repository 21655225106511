import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const FAQ = ({ faqData }) => {
  
  return (
    <div className="faq_section">

      {faqData.map((faqItem) => (
        <div key={faqItem.id} className="faq-card card  shadow mb-3 p-4" >
          <h4>{faqItem.question}</h4>
          <p>{faqItem.answer}</p>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
