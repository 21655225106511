import React, { useState, useEffect } from "react";
import "../CustomStyling/Products.css";
import { Link } from "react-router-dom/cjs/react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Products = ({ productData, pid }) => {
  console.log("Product", productData);
  const history = useHistory();
 
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const handelView = (id, pid) => {
    const requestBody = {
      category_id: id,
    };
    console.log("Product pid: ", pid);

    fetch("https://mcrsupplies.co.uk/admin/php-api/product.php", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData);
        // Navigating to the new page and passing the data via state
        history.push({
          pathname: `/sub-products`,
          state: { data: responseData.data, pid: pid },
        });
      })
      .catch((error) => {
        console.error("API Error:", error.message);
      });
  };

  return (
    <>
      <div className="product_section">
        <h4>Products</h4>
        <div className="products_">
          {productData?.map((product) => (
            <div className="link__">
              <div
                key={product.id}
                className=" mb-2 product_container "
               
              >
                <div className="card product_card ">
                  <div className="a_img">
                    <img
                      src={product.image}
                      className="card-img-top"
                      alt={product.category_name}
                    />
                  </div>
                  <div className="card-body ">
                    <div className="card_body_inner">
                      <h5 className="card-title">{product.category_name}</h5>
                      <p className="card-text">
                        {showFullDescription
                          ? product.category_description
                          : product.category_description.slice(0, 250)}
                        {product.category_description.length > 250 && (
                          <span
                            className="show-more-link"
                            onClick={toggleDescription}
                          >
                            {showFullDescription ? "Show less" : "Show more"}
                          </span>
                        )}
                      </p>
                    </div>

                    <button
                      className="btn cart_butt"
                      onClick={() =>
                        handelView(product.id, product.parent_category_id)
                      }
                    >
                      View Product
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Products;
