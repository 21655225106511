import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { CartProvider } from "./context/cart_context";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const initialOptions = {
  "client-id":
    "AQ6jdl6kUnkYYu0rOTMaNiTR_rC0RG4TaK7gqi63B7Gt75BrLbWhoSanSddScBKRmHxBltOnrc9SB3jl",
  currency: "GBP",
};
ReactDOM.render(
  <>
    <BrowserRouter>
      <PayPalScriptProvider options={initialOptions}>
        <CartProvider>
          <App />
        </CartProvider>
      </PayPalScriptProvider>
    </BrowserRouter>
  </>,
  document.getElementById("root")
);
