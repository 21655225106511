import React, { useEffect, useState } from "react";
import "../CustomStyling/Footer.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  ArrowRightOutlined,
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  MailFilled,
  MailOutlined,
  MailTwoTone,
  TwitterCircleFilled,
  TwitterOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
const FooterSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  });
  return (
    <div className="footer_section">
      <footer>
        <div className="row">
          <div className="col">
            <img src="./Images/logo_.png" className="footer__logo" />
            <p>
              Subscribe of our Newsletter to get latest update all time and get
              upto 15% discount on a product and chance to win a lucky draw
            </p>
          </div>
          <div className="col">
            <h3>Menus</h3>
            <ul>
              <li>
                <Link to="#">Products</Link>
              </li>
              <li>
                <Link to="#">About Us</Link>
              </li>
              <li>
                <Link to="#">Fabrication</Link>
              </li>
              <li>
                <Link to="#">Accessories</Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h3>Information</h3>
            <ul>
              <li>
                <Link to="#">ADS Road</Link>
              </li>
              <li>
                <Link to="#">Next field, Bangka shop</Link>
              </li>
              <li>
                <Link to="#" className="email__">
                  info@mcrsupplies.com
                </Link>
              </li>
              <li>
                <Link to="#">123 4567 890</Link>
              </li>
            </ul>
          </div>
          <div className="col">
            <h3>Newsletter</h3>
            <form className="news__letter">
              <input type="email" placeholder="Enter Your Email" />
              <button>
                {/* <ArrowRightOutlined /> */}
                Subscribe
                <MailFilled style={{ fontSize: "20px", marginLeft: "5px" }} />
              </button>
            </form>
            <div className="social__area">
              <h4>Follow Us on :</h4>
              <div className="social__icons">
                <FacebookFilled
                  style={{ fontSize: "26px", cursor: "pointer" }}
                />
                <TwitterCircleFilled
                  style={{ fontSize: "26px", cursor: "pointer" }}
                />
                <WhatsAppOutlined
                  style={{ fontSize: "26px", cursor: "pointer" }}
                />
                <InstagramFilled
                  style={{ fontSize: "26px", cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <p className="copy_right">MCR Supplies © 2024</p>
      </footer>
    </div>
  );
};

export default FooterSection;
