import AOS from "aos";
import "aos/dist/aos.css";

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

const SubProductCategory = ({ productData }) => {
  const history = useHistory();
  useEffect(() => {
    AOS.init({ duration: 1000 });
  });

  const location = useLocation();
  const pid = location.state ? location.state.pid : null;
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const handelView = (id, title) => {
    const requestBody = {
      product_id: id,
    };
    // console.log(id);

    fetch("https://mcrsupplies.co.uk/admin/php-api/product_item.php", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData);
        // Navigating to the new page and passing the data via state
        history.push({
          pathname: `/product-detail`,
          state: { data: responseData.data, pid: pid, title: title },
        });
      })
      .catch((error) => {
        console.error("API Error:", error.message);
      });
  };

  return (
    <>
      <div className="product_section">
        <h4>Products</h4>
        <div className="products_">
          {productData.map((product) => (
            <div className="link__">
              <div
                key={product.product_id}
                className=" mb-2 product_container "
                
              >
                <div className="card product_card">
                  <div className="a_img">
                    <img
                      src={product.product_image}
                      className="card-img-top"
                      alt={product.product_title}
                    />
                  </div>
                  <div className="card-body ">
                    <div className="card_body_inner">
                      <h5 className="card-title">{product.product_title}</h5>
                      <p className="card-text">
                        {product.product_description && // Check if category_description exists
                          (showFullDescription
                            ? product.product_description
                            : product.product_description.slice(0, 250))}
                        {product.product_description &&
                          product.product_description.length > 250 && (
                            <span
                              className="show-more-link"
                              onClick={toggleDescription}
                            >
                              {showFullDescription ? "Show less" : "Show more"}
                            </span>
                          )}
                      </p>
                    </div>

                    <button
                      className="btn cart_butt"
                      onClick={() =>
                        handelView(product.product_id, product.product_title)
                      }
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SubProductCategory;
