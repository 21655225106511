import React, { useEffect, useState } from "react";
import "../CustomStyling/ProductDataView.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useCartContext } from "../context/cart_context";
import { message } from "antd";

const ProductDataView = ({ data, title, pid }) => {
  console.log("View PID",pid)
  
  const { addToCart } = useCartContext();
  const history = useHistory();
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  const [ralCode, setRalCode] = useState("");
  const [availableQuantity, setAvailableQuantity] = useState(0);
  const [c_id, setC_id] = useState(0);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedThickness, setSelectedThickness] = useState("");
  const [thicknesses, setThicknesses] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [picture, setPicture] = useState("");
  const [instructions, setInstructions] = useState("");
  const [show, setShow] = useState(true); // Set show to true by default
  // console.log("PID", pid);
 

  // Function to generate a unique key of 4 numbers
  const generateUniqueKey = () => {
    const key = Math.floor(4000 + Math.random() * 6000); // Generate a random number between 1000 and 9999
    return key.toString(); // Convert the number to string
  };

  useEffect(() => {
    // Generate a new unique key whenever the component mounts or the dependency changes
    const newKey = generateUniqueKey();
    setC_id(newKey);

    // Find unique colors
    const uniqueColors = new Set(data.map((item) => item.color));
    setColors(Array.from(uniqueColors));

    // Set default selected color
    const defaultColor = Array.from(uniqueColors)[0];
    setSelectedColor(defaultColor);

    // Find unique sizes and thicknesses based on the selected color
    const sizesSet = new Set();
    const thicknessesSet = new Set();
    data.forEach((item) => {
      if (item.color === defaultColor) {
        if (item.size) sizesSet.add(item.size);
        if (item.thickness) thicknessesSet.add(item.thickness);
      }
    });

    setSizes(Array.from(sizesSet));
    setThicknesses(Array.from(thicknessesSet));

    // Set default selected size and thickness
    const defaultSize = Array.from(sizesSet)[0];
    const defaultThickness = Array.from(thicknessesSet)[0];
    setSelectedSize(defaultSize);
    setSelectedThickness(defaultThickness);

    // Find the default price, RAL code, and available quantity based on the default color, size, and thickness
    const defaultProduct = data.find(
      (item) =>
        item.color === defaultColor &&
        item.size === defaultSize &&
        item.thickness === defaultThickness
    );
    if (defaultProduct) {
      setPrice(defaultProduct.product_price);
      setRalCode(defaultProduct.ralCode);
      setAvailableQuantity(defaultProduct.product_available_quantity);
      setSelectedProducts(defaultProduct);
      setPicture(defaultProduct.product_picture);
    }
    setShow(pid !== 1);
  }, []); // Empty dependency array means this effect runs only once after the component mounts

  // Function to handle color selection
  const handleColorSelection = (color) => {
    setSelectedColor(color);
    // Find unique sizes and thicknesses based on the selected color
    const sizesSet = new Set();
    const thicknessesSet = new Set();
    data.forEach((item) => {
      if (item.color === color) {
        if (item.size) sizesSet.add(item.size);
        if (item.thickness) thicknessesSet.add(item.thickness);
      }
    });

    setSizes(Array.from(sizesSet));
    setThicknesses(Array.from(thicknessesSet));
    setSelectedSize(Array.from(sizesSet)[0]); // Set default selected size
    setSelectedThickness(Array.from(thicknessesSet)[0]); // Set default selected thickness

    // Find the price, RAL code, and available quantity based on the selected color, size, and thickness
    const selectedProduct = data.find(
      (item) =>
        item.color === color &&
        item.size === selectedSize &&
        item.thickness === selectedThickness
    );
    if (selectedProduct) {
      setPrice(selectedProduct.product_price);
      setRalCode(selectedProduct.ralCode);
      setAvailableQuantity(selectedProduct.product_available_quantity);
      setSelectedProducts(selectedProduct);
      setPicture(selectedProduct.product_picture);
    }
  };
  const handelSizeChange = (size) => {
    setSelectedSize(size);
    // Find the price, RAL code, and available quantity based on the selected color, size, and thickness
    const selectedProduct = data.find(
      (item) =>
        item.color === selectedColor &&
        item.size === selectedSize &&
        item.thickness === selectedThickness
    );
    if (selectedProduct) {
      setPrice(selectedProduct.product_price);
      setRalCode(selectedProduct.ralCode);
      setAvailableQuantity(selectedProduct.product_available_quantity);
      setSelectedProducts(selectedProduct);
      setPicture(selectedProduct.product_picture);
    }
  };
  const handelThicknessChange = (thickness) => {
    setSelectedThickness(thickness);
    // Find the price, RAL code, and available quantity based on the selected color, size, and thickness
    const selectedProduct = data.find(
      (item) =>
        item.color === selectedColor &&
        item.size === selectedSize &&
        item.thickness === selectedThickness
    );
    if (selectedProduct) {
      setPrice(selectedProduct.product_price);
      setRalCode(selectedProduct.ralCode);
      setAvailableQuantity(selectedProduct.product_available_quantity);
      setSelectedProducts(selectedProduct);
      setPicture(selectedProduct.product_picture);
    }
  };

  // Update the updatePrice function
  const updatePrice = (char) => {
    if (char === "+") {
      if (availableQuantity > 0) {
        setQuantity((prevQuantity) => prevQuantity + 1);
        setAvailableQuantity((prevQuantity) => prevQuantity - 1);
      } else {
        message.warning("Out of Stock");
      }
    } else if (char === "-") {
      if (quantity > 1) {
        setQuantity((prevQuantity) => prevQuantity - 1);
        setAvailableQuantity((prevQuantity) => prevQuantity + 1);
      }
    }
  };

  const vat = (price * quantity * 0.2).toFixed(2);

  const handleAddToCart = () => {
    const productInfo = {
      c_id: c_id,
      product_item_id: selectedProducts.product_item_id,
      title: title + selectedSize + " " + selectedThickness,
      quantity: quantity,
      price: price * quantity + parseFloat(vat),
      instructions: instructions,
      image: selectedProducts.product_image,
    };
    addToCart(productInfo);
    history.push("/cart");
  };

  return (
    <div className="view__">
      <div className="left_view">
        <div className="img_box">
          <img src={selectedProducts.product_image} alt="" />
        </div>
      </div>
      <div className="right_view">
        <h4>{title} </h4>
        <div className="ral_code">
          {show && <span>RAL: {selectedProducts.ral_code} </span>}
          <span
            className={`stock ${
              availableQuantity === 0 ? "out-of-stock" : "in-stock"
            }`}
          >
            {availableQuantity === 0
              ? "Out of Stock"
              : "In Stock: " + availableQuantity}
          </span>
        </div>
        {show && (
          <div className="thickness__">
            <h5>Thickness</h5>
            <div className="radio_btn">
              {thicknesses.map((thickness, index) => (
                <React.Fragment key={index}>
                  <input
                    type="radio"
                    id={`thickness-${index}`}
                    name="fav_thickness"
                    value={thickness}
                    checked={thickness === selectedThickness}
                    onChange={() => handelThicknessChange(thickness)}
                  />
                  <label htmlFor={`thickness-${index}`}>{thickness}</label>
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
        <div className="sizes_">
          <h5>Sizes</h5>
          <div className="radio_btn">
            {sizes.map((size, index) => (
              <React.Fragment key={index}>
                <input
                  type="radio"
                  id={`size-${index}`}
                  name="fav_size"
                  value={size}
                  checked={size === selectedSize}
                  onChange={() => handelSizeChange(size)}
                />
                <label htmlFor={`size-${index}`}>{size}</label>
              </React.Fragment>
            ))}
          </div>
        </div>
        {/* Color Slider */}
        {show && (
          <div className="colors__">
            <h5>Colors</h5>
            <div className="color__container">
              {colors.map((color, index) => (
                <div
                  key={index}
                  className="cir__"
                  style={{
                    borderRadius: "50%",
                    margin: "5px",
                    cursor: "pointer",
                    backgroundColor: color,
                    border: selectedColor === color ? "2px solid #000" : "none",
                  }}
                  onClick={() => handleColorSelection(color)}
                ></div>
              ))}
            </div>
          </div>
        )}
        <div className="quantity__box">
          <div>
            <h5>Quantity</h5>
            <div className="quantity_area ">
              <button
                className="minus_ shadow"
                onClick={() => updatePrice("-")}
              >
                -
              </button>
              <span className="shadow">{quantity}</span>
              <button className="plus_ shadow" onClick={() => updatePrice("+")}>
                +
              </button>
            </div>
          </div>
          <div className="total__">
            <span>
              Subtotal ${price * quantity} + VAT ={" "}
              {parseFloat(price * quantity) + parseFloat(vat)}
            </span>
          </div>
        </div>
        {show && (
          <div className="instruction__ ">
            <h5>Cutting Instruction</h5>
            <textarea
              type="text"
              className="shadow"
              rows={2}
              placeholder="Cutting Instructions Here..."
              onChange={(value) => setInstructions(value)}
            ></textarea>
          </div>
        )}

        <button className="add_cart" onClick={handleAddToCart}>
          <img src="./Images/shop-1.png" alt="" />
          <span>Add To Cart</span>
        </button>
      </div>
    </div>
  );
};

export default ProductDataView;
