import React, { useState, useEffect } from "react";
import "../CustomStyling/Main.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Main = () => {
  const data = [
    {
      id: 1,
      heading: "Heading 1",
      paragraph: "We provide all of your sign material needs",
    },
    {
      id: 2,
      heading: "Heading 2",
      paragraph: "Another set of sign material needs",
    },
    {
      id: 2,
      heading: "Heading 2",
      paragraph: "Variety of Light Boxes",
    },
    // Add more data objects as needed
  ];

  const images = [
    "./Images/banner_img_1.png",
    "./Images/222.png",
    "./Images/333.png",
    // Add more image paths as needed
  ];
  useEffect(() => {
    AOS.init({ duration: 1000 });
  });
  const [index, setIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) =>
        prevIndex === data.length - 1 ? 0 : prevIndex + 1
      );
      setAnimationClass("fadeInUp"); // Trigger animation class
      setTimeout(() => {
        setAnimationClass(""); // Remove animation class after animation duration
      }, 1000);
    }, 5000);

    return () => clearInterval(interval);
  }, [index, data.length]);

  return (
    <div className="main_container">
      <div className={`main_bg content ${animationClass}`}>
        <div className="main__left" data-aos="fade-right">
          <h1>
            <img src="./Images/mcr.png" alt="" />
          </h1>
          <h4>
            <img src="./Images/side_logo.png" alt="" />
          </h4>
          <p className={`content ${animationClass}`}>{data[index].paragraph}</p>
        </div>
        <div className="main__right" data-aos="fade-left">
          <div className={` vector__img content ${animationClass}`}>
            <img src={images[index]} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
