import React from "react";

const ProductDescription = ({ descriptionData }) => {
  return (
    <div className="description-section p-3">
      <h2>{descriptionData.title}</h2>
      <div className="points">
        <h4>Key Features:</h4>
        <ol>
          {descriptionData.points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ol>
      </div>
      <div className="details">
        {descriptionData.details.map((detail, index) => (
          <div key={index}>
            <h3>{detail.heading}</h3>
            <p>{detail.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductDescription;
