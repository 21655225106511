import React, { useEffect, useState } from "react";
import { Rate, Button } from "antd";
import "../CustomStyling/Reviews.css"; // Optionally, you can create a separate CSS file for custom styling
import AOS from "aos";
import "aos/dist/aos.css";
const Reviews = ({ reviewData }) => {
  const [showMore, setShowMore] = useState(false);

  const visibleReviews = showMore ? reviewData : reviewData.slice(0, 7);
  useEffect(() => {
    AOS.init({ duration: 1000 });
  });
  const handleShowMore = () => {
    setShowMore(true);
  };

  return (
    <div>
      <div className="review_area">
        {visibleReviews.map((review) => (
          <div key={review.id} className="review_card card shadow p-4 mb-3">
            <div className="review_content">
              <div className="rating px-3">
                <Rate
                  allowHalf
                  defaultValue={parseFloat(review.rating)}
                  style={{ color: "#52c41a" }}
                />
              </div>
              <div className="info__ d-flex mt-2 mb-2 ">
                <img src={`./Images/review.png`} alt="" />
                <div className="name_">
                  <label>{review.name} </label>
                  <span>{review.designation} </span>
                </div>
              </div>
              <div className="rev_desc px-3">
                <p>{review.review}</p>
              </div>
            </div>
          </div>
        ))}
        {!showMore && reviewData.length > 7 && (
          <div className="show__">
            <span onClick={handleShowMore}>Show More</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Reviews;
