import React, { useEffect, useRef, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "../CustomStyling/NavbarStyle.css"; // Ensure smooth animation styles are defined here
import { useCartContext } from "../context/cart_context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Navbar = ({ response }) => {
  const history = useHistory();
  const { cart } = useCartContext();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [click, setClick] = useState(false);
  const submenuRef = useRef(null);
  const sidebarRef = useRef(null);
  console.log(response);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const toggleSide = () => {
    setClick((prevClick) => !prevClick);
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !event.target.classList.contains("hamIcon") &&
        !submenuRef.current?.contains(event.target) &&
        !event.target.classList.contains("dropdown-icon")
      ) {
        setClick(false);
        setIsSubMenuOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [sidebarRef, submenuRef]);

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (
  //       click &&
  //       sidebarRef.current &&
  //       !sidebarRef.current.contains(event.target) &&
  //       submenuRef.current &&
  //       !submenuRef.current.contains(event.target)
  //     ) {
  //       setClick(false);
  //       setIsSubMenuOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleOutsideClick);

  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, [click]);

  // Close the sidebar and sub-menu when a link is clicked

  const handleLinkClick = () => {
    setClick(false);
    setIsSubMenuOpen(false);
  };

  const handelProductClick = (id, path) => {
    handleLinkClick();
    const requestBody = {
      category: "sub",
      p_category_id: id,
    };
    console.log("CategoryId", id);
    fetch("https://mcrsupplies.co.uk/admin/php-api/product_category.php", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((responseData) => {
        // Navigating to the new page and passing the data via state
        history.push({
          pathname: `/${path}`,
          state: { data: responseData.data, pid: id },
        });
      })
      .catch((error) => {
        console.error("API Error:", error.message);
      });
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div className="top_bar">
          <div className="logo_">
            <div className="ham_icon" onClick={toggleSide}>
              <img src="./Images/ham.png" alt="" className="hamIcon" />
            </div>
            <div className="logo_1" onClick={() => history.push("/")}>
              <img src="./Images/logo.png" alt="" />
            </div>
            {/* <div className="logo_2">
              <img src="./Images/logo_.png" alt="" />
            </div> */}
          </div>
          <div className="right_">
            <div className="search_ shadow">
              <input type="text" />
              <div className="search_logo">
                <img src="./Images/search.png" alt="" />
              </div>
            </div>
            <Link className="cart_" to="/cart">
              <img src="./Images/shop.png" alt="" />
              {cart.length > 0 && <span className="badge">{cart.length}</span>}
            </Link>
            <div className="log_in shadow">
              <Link to="login">Login</Link>
            </div>
          </div>
        </div>
        <div
          className={click ? "menu_bar active_" : "menu_bar"}
          ref={sidebarRef}
        >
          <div className="nav_">
            <div className="close__nav" onClick={() => setClick(false)}>
              <img src="./Images/cross.png" alt="" />
            </div>
            <ul className="nav_links">
              <li>
                <NavLink
                  exact
                  to="/"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  Home
                </NavLink>
              </li>
              <li className="drop__" onClick={toggleSubMenu} ref={submenuRef}>
                <NavLink
                  to="/"
                  activeClassName={`no-active ${isSubMenuOpen ? "active" : ""}`}
                >
                  <span>Products</span>{" "}
                  <img
                    src="./Images/down-arrow.png"
                    alt=""
                    className={`dropdown-icon translate_ ${
                      isSubMenuOpen ? "translate-arrow" : ""
                    }`}
                  />
                </NavLink>
                {isSubMenuOpen && (
                  <div
                    className={`sub-menu-wrap ${
                      isSubMenuOpen ? "open-menu" : ""
                    }`}
                  >
                    <div className="sub-menu" ref={submenuRef}>
                      <ul className="sub-links">
                        {response.map((category) => (
                          <li key={category.id}>
                            <a
                              style={{ cursor: "pointer" }}
                              activeClassName="active"
                              onClick={() =>
                                handelProductClick(
                                  category.id,
                                  category.category_name
                                )
                              }
                            >
                              {category.category_name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </li>
              <li>
                <NavLink
                  to="/Fabrication"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  Fabrication
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/Get-A-Qoute"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  Get A Qoute
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/AboutUs"
                  activeClassName="active"
                  onClick={handleLinkClick}
                >
                  About Us
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
