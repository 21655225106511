import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import "../CustomStyling/Login.css";
import {
  FacebookFilled,
  InstagramFilled,
  TwitterCircleFilled,
} from "@ant-design/icons";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    try {
      setLoading(true); // Set loading state to true while login API call is in progress

      // Make login API call
      const response = await fetch(
        "https://mcrsupplies.co.uk/admin/php-api/login.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.uStatus === "VERIFIED") {
          // Save user ID to local storage with expiration time of 10 minutes
          localStorage.setItem("userId", data.userId);
          setTimeout(() => {
            localStorage.removeItem("userId");
          }, 10 * 60 * 1000); // 10 minutes in milliseconds

          // Redirect to home page
          setRedirectToHome(true);
        }
      } else {
        // Handle login error
        console.error("Login failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading state back to false after login API call completes
    }
  };

  if (redirectToHome || localStorage.getItem("userId")) {
    return <Redirect to="/" />;
  }

  return (
    <div className="login__container">
      <div className="login__form">
        <h2>Login</h2>
        <div className="input__group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input__group">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="eye__icon" onClick={handleTogglePassword}>
            {showPassword ? "👁️" : "👁️‍🗨️"}
          </span>
        </div>
        <button onClick={handleLogin}>
          {loading ? "Loading..." : "Login"} {/* Show loading text if loading state is true */}
        </button>
        <p className="register">
          Don't have an account? <Link to="/signUp">Create New Account</Link>
        </p>
      </div>
      <div className="social__icons">
        {/* Add your social icons here */}
        <a href="#">
          <FacebookFilled style={{ fontSize: "30px" }} />
        </a>
        <a href="#">
          <TwitterCircleFilled style={{ fontSize: "30px" }} />
        </a>
        <a href="#">
          <InstagramFilled style={{ fontSize: "30px" }} />
        </a>
      </div>
      <div className="copyright__section">
        <p>© 2024 MCR Supplies. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Login;
